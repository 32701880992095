// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-product-ai-ops-js": () => import("./../../../src/pages/products/product-AIOps.js" /* webpackChunkName: "component---src-pages-products-product-ai-ops-js" */),
  "component---src-pages-products-product-auto-js": () => import("./../../../src/pages/products/product-auto.js" /* webpackChunkName: "component---src-pages-products-product-auto-js" */),
  "component---src-pages-products-product-ism-core-js": () => import("./../../../src/pages/products/product-ism-core.js" /* webpackChunkName: "component---src-pages-products-product-ism-core-js" */),
  "component---src-pages-products-product-ism-js": () => import("./../../../src/pages/products/product-ism.js" /* webpackChunkName: "component---src-pages-products-product-ism-js" */),
  "component---src-pages-products-product-luma-js": () => import("./../../../src/pages/products/product-luma.js" /* webpackChunkName: "component---src-pages-products-product-luma-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

